import React from 'react';
import { motion } from 'framer-motion'; // Import Framer Motion for animations
import { Link } from 'react-router-dom';

const HomePage = () => {
  return (
    <div className="min-h-screen bg-black text-white">
      {/* Hero Section with Motion */}
      <section className="bg-gray-900 py-16">
        <div className="container mx-auto text-center">
          <motion.h1
            className="text-5xl font-bold text-green-500"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            Transform Your Business. Automate Success.
          </motion.h1>
          <motion.p
            className="mt-4 text-xl text-gray-400"
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6, delay: 0.3 }}
          >
            Save time, reduce costs, and boost productivity with our custom automation solutions.
          </motion.p>
          <motion.p
            className="mt-4 text-xl text-gray-400"
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6, delay: 0.5 }}
          >
            Let’s Streamline Your Business!
          </motion.p>
          <motion.div
            className="mt-8 flex justify-center space-x-4"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8, delay: 0.8 }}
          >
            <Link to="/contact">
              <button className="px-6 py-3 bg-green-600 text-white font-semibold rounded-lg shadow-md hover:bg-green-500 transition duration-300">
                Get a Free Consultation
              </button>
            </Link>
            <Link to="/services">
              <button className="px-6 py-3 bg-green-600 text-white font-semibold rounded-lg shadow-md hover:bg-green-500 transition duration-300">
                Explore Our Services
              </button>
            </Link>
          </motion.div>
        </div>
      </section>

      {/* Service Highlights with Motion */}
      <section className="py-16 bg-gray-800">
        <div className="container mx-auto">
          <h2 className="text-3xl font-bold text-center text-green-400">Our Services</h2>
          <div className="mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 text-center">
            {[
              { title: "Web Development", icon: "fas fa-code", description: "Custom websites that enhance your business presence." },
              { title: "Automation Solutions", icon: "fas fa-sync-alt", description: "Automate workflows and improve efficiency." },
              { title: "Custom Integrations", icon: "fas fa-cogs", description: "Seamlessly integrate your existing systems." },
              { title: "SaaS Subscription Management", icon: "fas fa-chart-line", description: "Manage and optimize your subscriptions." }
            ].map((service, index) => (
              <motion.div
                key={index}
                className="bg-gray-900 p-6 rounded-lg shadow-md"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: index * 0.2 }}
              >
                <div className="text-4xl text-green-600 mb-4">
                  <i className={service.icon}></i>
                </div>
                <h3 className="text-xl font-semibold text-white">{service.title}</h3>
                <p className="mt-2 text-gray-400">{service.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Lead Capture Section */}
      {/* Lead Capture Section with Image Background */}
<section
  className="py-16 bg-cover bg-center"
  style={{
    backgroundImage: "url(/assets/gearzzz1.webp)",
    backgroundBlendMode: "overlay",
    backgroundColor: "rgba(0, 0, 0, 0.7)", // Slight dark overlay for readability
  }}
>
  <div className="container mx-auto text-center">
    <h2 className="text-3xl font-bold text-white">Get Your Free Quote</h2>
    <p className="mt-4 text-gray-300">
      Tell us about your business needs, and we’ll provide a tailored solution.
    </p>
    <form
  action="https://formspree.io/f/mrbznyjy"
  method="POST"
  class="mt-8 flex flex-col space-y-4 items-center"
>
  <input
    type="text"
    name="name"
    placeholder="Your Name"
    class="px-4 py-3 w-1/2 text-gray-900 rounded-lg"
    required
  />
  <input
    type="email"
    name="email"
    placeholder="Your Email"
    class="px-4 py-3 w-1/2 text-gray-900 rounded-lg"
    required
  />
  <textarea
    name="message"
    placeholder="Your Message"
    class="px-4 py-3 w-1/2 text-gray-900 rounded-lg h-32"
    required
  ></textarea>
  <button
    type="submit"
    class="px-6 py-3 bg-green-600 text-white font-semibold rounded-lg shadow-md hover:bg-green-500 transition duration-300"
  >
    Send
  </button>
</form>

  </div>
</section>

    </div>
  );
};

export default HomePage;

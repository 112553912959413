import React from 'react';

const ContactPage = () => {
  return (
    <div className="bg-black text-white min-h-screen py-16 flex flex-col items-center">
      <h1 className="text-4xl font-bold mb-4 text-green-500">Contact Us</h1>
      <p className="text-xl text-gray-300 mb-8">
        Reach out to us for any business inquiries or to get a free consultation.
      </p>
      <form
  action="https://formspree.io/f/mnnaegwl"
  method="POST"
  className="w-full max-w-lg flex flex-col space-y-4 items-center"
>
  <input
    type="text"
    name="name"
    placeholder="Your Name"
    className="px-4 py-3 w-full text-gray-200 rounded-lg bg-gray-700 focus:bg-gray-600 focus:outline-none"
    required
  />
  <input
    type="email"
    name="email"
    placeholder="Your Email"
    className="px-4 py-3 w-full text-gray-200 rounded-lg bg-gray-700 focus:bg-gray-600 focus:outline-none"
    required
  />
  <textarea
    name="message"
    placeholder="Your Message"
    className="px-4 py-3 w-full text-gray-200 rounded-lg bg-gray-700 focus:bg-gray-600 focus:outline-none h-32"
    required
  ></textarea>
  <button
    type="submit"
    className="px-6 py-3 bg-green-500 text-white font-semibold rounded-lg shadow-md hover:bg-green-400 transition duration-300"
  >
    Send Message
  </button>
</form>

    </div>
  );
};

export default ContactPage;

import React from 'react';
import { motion } from 'framer-motion';

const GigaWorkflowPDFParser = () => {
  return (
    <div className="min-h-screen bg-black text-white py-16 px-4">
      {/* Top Image */}
      <div className="container mx-auto mb-8">
        <img
          src="/assets/invoice.png"
          alt="GigaWorkflow PDF Parser Example 1"
          className="w-80 h-80 object-cover rounded-lg shadow-lg mx-auto"
        />
      </div>

      {/* Main Text Content */}
      <div className="container mx-auto text-center">
        <motion.h1
          className="text-5xl font-bold text-green-500 mb-8"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          PDF Parser - Automate Your Data Extraction with Seamless Precision
        </motion.h1>
        <motion.p
          className="text-xl text-gray-400 mb-8"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.6, delay: 0.3 }}
        >
          PDF documents are the backbone of many business processes, from invoices to contracts, resumes, and more. But manually extracting data from PDFs can be both time consuming and prone to error. This PDF parsing system offers a highly adaptable automation solution that transforms these static documents into actionable data, effortlessly integrated into your business operations.
        </motion.p>

        <h2 className="text-3xl font-semibold text-green-400 mb-6">
  What Can a PDF Parser Do for Your Business?
</h2>
<p className="text-lg text-gray-400 mb-6">
  Data extraction is at the heart of many routine business tasks. By automating this process, the gigaWorkflow can help you to streamline various workflows, saving your business hours of manual work and reducing the risk of human error. Here's how PDF parsing can help:
</p>

<ul className="list-disc list-inside text-left mx-auto max-w-3xl mb-8 text-gray-400">
  <li>
    <strong>Automated Invoicing:</strong> Extract essential details such as dates, amounts, and invoice numbers, and feed them directly into your accounting systems. This drastically reduces manual data entry time and improves accuracy.
  </li>
  <li>
    <strong>Resume Parsing for Recruitment:</strong> For businesses sorting through hundreds of resumes, the parser can extract candidate information—names, qualifications, experience—and sort it for easier analysis and comparison.
  </li>
  <li>
    <strong>Contract Management:</strong> Quickly pull critical details from legal documents, including terms, parties, and deadlines, allowing you to better manage your agreements.
  </li>
  <li>
    <strong>Data Archiving and Analytics:</strong> Automatically archive important documents while extracting key metrics for reporting and business intelligence.
  </li>
  <li>
    <strong>Compliance and Audit Readiness:</strong> Simplify your document audits by automating the extraction and organization of compliance-related data.
  </li>
</ul>


        {/* Midway Image */}
        <div className="container mx-auto mb-8">
          <img
            src="/assets/invoice1.png"
            alt="GigaWorkflow PDF Parser Example 2"
            className="w-80 h-80 object-cover rounded-lg shadow-lg mx-auto"
          />
        </div>

        <h2 className="text-3xl font-semibold text-green-400 mb-6">
          Why This Matters for Your Business
        </h2>
        <p className="text-lg text-gray-400 mb-6">
          Consider the time and resources it takes to manually process hundreds—or thousands—of PDFs each month. Studies show that 75% of businesses report a measurable improvement in productivity after implementing automation solutions. Automating PDF data extraction can reduce invoice processing time by 60%, speeding up cash flow and reducing operational bottlenecks.
        </p>

        <p className="text-lg text-gray-400 mb-6">
          Beyond the financial impact, the parser improves data accuracy by eliminating manual errors, which can cost businesses. By reducing mistakes, you streamline operations, improve accuracy, and ensure faster, more reliable data processing.
        </p>

        <h2 className="text-3xl font-semibold text-green-400 mb-6">
          Flexibility and Scalability
        </h2>
        <p className="text-lg text-gray-400 mb-6">
          The beauty of this PDF parsing system is its adaptability. Whether you’re processing a handful of invoices or managing vast databases of legal documents, this tool can scale to meet the unique needs of your business. Moreover, it’s not limited to one type of document—this solution can be expanded to process any PDF, extracting custom data points that are essential for your specific workflows.
        </p>

        <h2 className="text-3xl font-semibold text-green-400 mb-6">
          The gigaWorkflow Advantage
        </h2>
        <p className="text-lg text-gray-400 mb-12">
          At gigaWorkflow, we believe automation should work for you, not the other way around. Our PDF parser is built to integrate seamlessly into your existing systems, minimizing disruption while maximizing efficiency. Whether you’re looking to streamline your invoicing, enhance your recruitment process, or improve document management, the gigaWorkflow PDF parser offers a reliable, scalable, and highly adaptable solution for automating data extraction across your entire business.
        </p>

        {/* Buttons */}
        <div className="space-y-4 md:space-y-0 md:space-x-4 flex flex-col md:flex-row justify-center items-center">
          <a 
            href="/contact" 
            className="w-full md:w-auto px-6 py-3 bg-transparent border-2 border-green-600 text-green-600 font-semibold rounded-lg shadow-md hover:bg-green-600 hover:text-white transition duration-300 text-center"
          >
            Contact Us
          </a>
        </div>
      </div>
    </div>
  );
};

export default GigaWorkflowPDFParser;

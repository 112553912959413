import React from 'react';
import { motion } from 'framer-motion';

const GigaWorkflowBlog = () => {
  return (
    <div className="min-h-screen bg-black text-white py-16 px-4">
      {/* Top Image */}
      <div className="container mx-auto mb-8">
        <img
          src="/assets/blog2.webp"
          alt="GigaWorkflow Blog Example 1"
          className="w-80 h-80 object-cover rounded-lg shadow-lg mx-auto"
        />
      </div>

      {/* Main Text Content */}
      <div className="container mx-auto text-center">
        <motion.h1
          className="text-5xl font-bold text-green-500 mb-8"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          gigaWorkflow Blog - Automated Content Creation for Maximum Efficiency
        </motion.h1>
        <motion.p
          className="text-xl text-gray-400 mb-8"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.6, delay: 0.3 }}
        >
          Content is king, but creating high-quality articles consistently can be time-consuming and resource-heavy. The gigaWorkflow blog takes the hassle out of content production, providing an automated solution that can generate, schedule, and publish articles with just the click of a button. This is not just any blog—it’s a tool built for businesses that understand the value of staying relevant and visible online, without sacrificing time.
        </motion.p>

        <h2 className="text-3xl font-semibold text-green-400 mb-6">
          The Power of an Automated Blog
        </h2>
        <p className="text-lg text-gray-400 mb-6">
          Automation in content creation is a game-changer. According to industry reports, businesses that post frequent, relevant content can see up to 67% more leads than those that don’t. The gigaWorkflow blog uses a streamlined process; a process that can allow your business to maintain a consistent voice without constant manual input.
        </p>

        <p className="text-lg text-gray-400 mb-6">
          Generate articles on demand. With a simple click, new blog posts are created, ensuring your content pipeline never runs dry. You can schedule content ahead of time, automate article releases, and enhance posts with custom images—boosting engagement and making your content more compelling.
        </p>

        {/* Midway Image */}
        <div className="container mx-auto mb-8">
          <img
            src="/assets/blog1.png"
            alt="GigaWorkflow Blog Example 2"
            className="w-80 h-80 object-cover rounded-lg shadow-lg mx-auto"
          />
        </div>

        <h2 className="text-3xl font-semibold text-green-400 mb-6">
          Why Does Your Business Need This?
        </h2>
        <p className="text-lg text-gray-400 mb-6">
          Research shows that websites with regularly updated blogs receive 434% more indexed pages in search engines, significantly improving SEO and driving organic traffic. Additionally, 60% of marketers say content marketing generates demand and nurtures leads—both essential for any growing business.
        </p>

        <p className="text-lg text-gray-400 mb-6">
          The gigaWorkflow blog not only saves you time but directly supports these business-critical goals by producing a steady stream of relevant, searchable content.
        </p>

        <h2 className="text-3xl font-semibold text-green-400 mb-6">
          Flexibility and Scalability
        </h2>
        <p className="text-lg text-gray-400 mb-6">
          As your business grows, so too can your blog. The gigaWorkflow blog can be easily upgraded to integrate more advanced features, including images, metadata, and SEO optimizations, ensuring that your content remains competitive and engaging. Whether you're a small business or scaling up, this system allows you to focus on what matters most—without worrying about your content calendar.
        </p>

        <h2 className="text-3xl font-semibold text-green-400 mb-6">
          The gigaWorkflow Advantage
        </h2>
        <p className="text-lg text-gray-400 mb-12">
          At gigaWorkflow, we understand that running a business involves wearing many hats. By automating your content creation process, we help free up valuable time for you to focus on core business activities. Automation doesn’t just streamline content—it improves consistency, aids in audience retention, and ultimately supports revenue growth. With articles being produced and published effortlessly, you can ensure that your website is always active and engaging.
        </p>

        {/* Buttons */}
        <div className="space-y-4 md:space-y-0 md:space-x-4 flex flex-col md:flex-row justify-center items-center">
          <a 
            href="/blog" 
            className="w-full md:w-auto px-6 py-3 bg-green-600 text-white font-semibold rounded-lg shadow-md hover:bg-green-500 transition duration-300 text-center"
          >
            View gigaWorkflow Blog
          </a>
          <a 
            href="/contact" 
            className="w-full md:w-auto px-6 py-3 bg-transparent border-2 border-green-600 text-green-600 font-semibold rounded-lg shadow-md hover:bg-green-600 hover:text-white transition duration-300 text-center"
          >
            Contact Us
          </a>
        </div>
      </div>
    </div>
  );
};

export default GigaWorkflowBlog;

import React from 'react';
import { motion } from 'framer-motion';

const IndeedJobScraper = () => {
  return (
    <div className="min-h-screen bg-black text-white py-16 px-4">
      {/* Top Image */}
      <div className="container mx-auto mb-8">
        <img
          src="/assets/indeed1.png"
          alt="Indeed Job Scraper Example 1"
          className="w-80 h-80 object-cover rounded-lg shadow-lg mx-auto"
        />
      </div>

      {/* Main Text Content */}
      <div className="container mx-auto text-center">
        <motion.h1
          className="text-5xl font-bold text-green-500 mb-8"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          Indeed Job Scraper - Automating Custom Job Applications with Precision
        </motion.h1>
        <motion.p
          className="text-xl text-gray-400 mb-8"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.6, delay: 0.3 }}
        >
          Finding the right job can be time-consuming, especially when trying to tailor resumes and cover letters to each opportunity. This Indeed Job Scraper takes the complexity out of the process by automating job searches and generating personalized applications. This isn’t just about filling forms—it’s about creating a highly adaptable system that tailors each application to the specific role, improving your chances of standing out.
        </motion.p>

        <h2 className="text-3xl font-semibold text-green-400 mb-6">
          How It Works
        </h2>
        <p className="text-lg text-gray-400 mb-6">
          The automation pulls relevant job postings from Indeed, compiles them into a spreadsheet, and—using the power of ChatGPT—generates a custom resume and cover letter for each job. This ensures that every application is tailored, highlighting your most relevant skills, experience, and qualifications in response to each specific job description.
        </p>

        <h2 className="text-3xl font-semibold text-green-400 mb-6">
          Benefits of Custom Responses
        </h2>
        <p className="text-lg text-gray-400 mb-6">
          Customizing applications is critical in today’s competitive job market. Research shows that 61% of recruiters are more likely to consider an applicant with a personalized resume or cover letter. Tailored applications demonstrate that the candidate has put thought into the process, making them more appealing.
        </p>

        <p className="text-lg text-gray-400 mb-6">
  This system ensures:
</p>

<ul className="list-disc list-inside text-left mx-auto max-w-3xl mb-8 text-gray-400">
  <li>
    <strong>Accurate matching of skills to job requirements:</strong> By analyzing the job descriptions, the tool generates resumes and cover letters that emphasize the most relevant experiences, making each application stronger.
  </li>
  <li>
    <strong>Time-saving efficiency:</strong> A process that typically takes hours—researching jobs, editing resumes, and writing cover letters—can now be completed in minutes, allowing for higher volume without sacrificing quality.
  </li>
  <li>
    <strong>Customization flexibility:</strong> The system is fully adaptable, meaning cover letters can be styled to reflect different tones, industries, or specific employer preferences.
  </li>
</ul>


        {/* Midway Image */}
        <div className="container mx-auto mb-8">
          <img
            src="/assets/indeed2.png"
            alt="Indeed Job Scraper Example 2"
            className="w-80 h-80 object-cover rounded-lg shadow-lg mx-auto"
          />
        </div>

        <h2 className="text-3xl font-semibold text-green-400 mb-6">
  Expanding the Possibilities: More Than Just Job Applications
</h2>
<p className="text-lg text-gray-400 mb-6">
  While the core function of this tool is job applications, its capabilities extend far beyond that. The system can be adapted for a range of business tasks, where automation and personalization are key. Here are a few examples:
</p>

<ul className="list-disc list-inside text-left mx-auto max-w-3xl mb-8 text-gray-400">
  <li>
    <strong>Bulk Outreach for Sales and Marketing:</strong> Automate the creation of tailored emails for potential clients, partners, or collaborators. By using the job scraper’s methodology, the tool can pull relevant company data and customize outreach messages, ensuring each contact feels personal.
  </li>
  <li>
    <strong>Custom Client Proposals:</strong> Instead of writing proposals from scratch, automate the process by pulling project information and generating tailored proposals for each potential client, addressing their unique needs.
  </li>
  <li>
    <strong>Resume Sorting for Recruiters:</strong> Businesses can use a modified version of this system to analyze incoming resumes, match them with job descriptions, and generate feedback or follow-up communication, streamlining the hiring process.
  </li>
  <li>
    <strong>Lead Generation and Follow-ups:</strong> Automatically pull data from lead sources, create custom follow-up emails or messages, and feed them into a CRM for easy management.
  </li>
</ul>


        <h2 className="text-3xl font-semibold text-green-400 mb-6">
          The Power of Automation for Customization
        </h2>
        <p className="text-lg text-gray-400 mb-6">
          Automation doesn’t have to mean losing the personal touch. In fact, it can enhance it. By using tools like ChatGPT, this system can tailor every response—whether it's a resume, cover letter, proposal, or email—ensuring it aligns with the recipient's needs while maintaining efficiency.
        </p>

        <p className="text-lg text-gray-400 mb-6">
          The flexibility of this automation means that it can be easily adapted to suit various business applications, saving time and improving accuracy in almost any repetitive task.
        </p>

        <h2 className="text-3xl font-semibold text-green-400 mb-6">
          The gigaWorkflow Advantage
        </h2>
        <p className="text-lg text-gray-400 mb-12">
          At gigaWorkflow, we recognize that every business has unique needs, which is why our automation systems are built to be as adaptable as they are powerful. Whether you're looking to streamline job applications, sales outreach, or client proposals, the Indeed Job Scraper can be customized to suit a wide range of applications. By automating repetitive tasks and injecting personalization where it matters most, we help businesses maintain both efficiency and quality.
        </p>

        {/* Buttons */}
        <div className="space-y-4 md:space-y-0 md:space-x-4 flex flex-col md:flex-row justify-center items-center">
          <a 
            href="/contact" 
            className="w-full md:w-auto px-6 py-3 bg-transparent border-2 border-green-600 text-green-600 font-semibold rounded-lg shadow-md hover:bg-green-600 hover:text-white transition duration-300 text-center"
          >
            Contact Us
          </a>
        </div>
      </div>
    </div>
  );
};

export default IndeedJobScraper;

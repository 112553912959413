import React, { useState, useEffect } from 'react';
import axios from 'axios';

const BlogPage = () => {
  const [blogPosts, setBlogPosts] = useState([]);
  const [expandedPostId, setExpandedPostId] = useState(null); // Tracks which post is expanded

  // Fetch blog posts from the backend
  useEffect(() => {
    axios.get('https://www.gigaworkflow.xyz/api/blogposts')
      .then((response) => {
        // Sort posts by date (newest first)
        const sortedPosts = response.data.sort((a, b) => new Date(b.date) - new Date(a.date));
        setBlogPosts(sortedPosts);
      })
      .catch((error) => {
        console.error('Error fetching blog posts:', error);
      });
  }, []);

  // Function to toggle expanded post
  const togglePost = (id) => {
    if (expandedPostId === id) {
      setExpandedPostId(null); // Collapse if the same post is clicked
    } else {
      setExpandedPostId(id); // Expand the clicked post
    }
  };

  return (
    <div className="bg-black text-white py-16 min-h-screen">
      <h1 className="text-4xl sm:text-5xl font-bold text-center text-green-500 mb-8 sm:mb-12">Our Blog</h1>
      <div className="container mx-auto px-4">
        <div className="flex flex-col space-y-6 sm:space-y-8">
          {blogPosts.map((post) => (
            <div 
              key={post._id} 
              className="p-4 sm:p-6 bg-gray-900 rounded-lg shadow-md"
              style={{ transition: 'all 0.3s ease-in-out' }}
            >
              {/* Title */}
              <h3 
                className="text-2xl sm:text-3xl font-bold text-green-400 cursor-pointer hover:text-green-300 mb-2"
                onClick={() => togglePost(post._id)}
              >
                {post.title}
              </h3>
              
              {/* Summary */}
              <p className="text-base sm:text-lg text-gray-300 mb-4">{post.summary}</p>

              {/* Expanded content */}
              {expandedPostId === post._id && (
                <div className="mt-4 text-gray-300 leading-relaxed">
                  <div
                    dangerouslySetInnerHTML={{ __html: post.content }} // Render raw HTML content
                  />
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};


export default BlogPage;

import React from 'react';
import { motion } from 'framer-motion';

// Animation variants
const fadeInUp = {
  hidden: { opacity: 0, y: 30 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.8, ease: 'easeInOut' } },
};

const staggerContainer = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { staggerChildren: 0.2 },
  },
};

const ServicesPage = () => {
  return (
    <motion.div
      className="container mx-auto py-16 px-6 bg-black text-white"
      initial="hidden"
      animate="visible"
      variants={staggerContainer}
    >
      {/* Header Section */}
      <motion.h1 className="text-5xl font-bold text-center text-green-500 mb-12" variants={fadeInUp}>
        Our Automation & Development Services
      </motion.h1>
      
      {/* Intro Section */}
      <motion.p className="text-lg leading-relaxed mb-8" variants={fadeInUp}>
        At <span className="text-green-500">gigaWorkflow</span>, we believe in empowering businesses to operate at their peak efficiency. In a world where time is money, our automation and web development services are designed to free up your valuable resources, reduce operational overhead, and allow you to focus on what truly matters—growing your business.
      </motion.p>
      <motion.p className="text-lg leading-relaxed mb-16" variants={fadeInUp}>
        Whether you need to automate everyday tasks, streamline your financial operations, or build an online presence that captivates and converts, <span className="text-green-500">gigaWorkflow</span> has the solution. We specialize in providing practical, scalable tools that deliver real, measurable results. Let us help you unlock the full potential of your business.
      </motion.p>

      {/* Contact Button with hover effect */}
      <motion.div className="text-center mb-16" variants={fadeInUp}>
        <motion.a
          href="/contact"
          className="bg-green-500 text-black px-8 py-4 rounded-full font-bold text-lg hover:bg-green-600 transition-colors"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          Schedule a Free Consultation
        </motion.a>
      </motion.div>

      {/* The Power of Automation Section */}
      <motion.h2 className="text-3xl font-bold text-green-500 mb-6" variants={fadeInUp}>
        The Power of Automation
      </motion.h2>
      <motion.p className="text-lg leading-relaxed mb-8" variants={fadeInUp}>
        Automation isn’t just a buzzword—it’s a game changer. Businesses that embrace automation are often more agile, more efficient, and more profitable. According to a report by McKinsey, companies that fully automate their operations can see up to a 20-30% reduction in operational costs, and employees can save over 10 hours per week by eliminating repetitive tasks.
      </motion.p>
      <motion.p className="text-lg leading-relaxed mb-16" variants={fadeInUp}>
        At <span className="text-green-500">gigaWorkflow</span>, we leverage cutting-edge tools and techniques to help you achieve that kind of transformation. From automating daily processes to integrating complex systems, our services are designed to simplify your operations and provide a more seamless experience for your team and your customers.
      </motion.p>

      {/* Core Services Section */}
      <motion.h2 className="text-4xl font-bold text-green-500 mb-8" variants={fadeInUp}>
        Our Core Services
      </motion.h2>

      {/* Full-width Cards for Core Services */}
      <div className="space-y-12">
        {/* Workflow Automation Card */}
        <motion.div className="bg-gray-800 p-8 rounded-lg shadow-lg" variants={fadeInUp} whileHover={{ scale: 1.02 }}>
          <h3 className="text-2xl font-bold text-green-400 mb-4">Workflow Automation: Maximize Efficiency Across Your Operations</h3>
          <p className="text-lg leading-relaxed mb-4">
            Repetitive, manual tasks can drain productivity and hinder growth. Our workflow automation services take the burden off your team by automating tasks like invoicing, scheduling, customer follow-ups, and more. This allows you to focus on scaling your business instead of getting bogged down by routine processes.
          </p>
          <p className="font-semibold text-green-400 mb-2">Why It Matters:</p>
          <ul className="list-disc list-inside text-lg mb-6">
            <li>Increased Productivity: Studies show businesses implementing workflow automation can see a 20% boost in employee productivity.</li>
            <li>Fewer Errors: Automation drastically reduces the potential for human error in critical operations.</li>
            <li>Scalable Solutions: Our automation systems are designed to scale with your business needs.</li>
          </ul>
          <p className="text-lg leading-relaxed italic mb-12">
            <span className="text-green-400">Example:</span> Businesses that automate invoice processing can cut down billing errors by up to 50%, while reducing the time spent on these tasks by 70%, as reported by the Institute of Finance and Management.
          </p>
        </motion.div>

        {/* CRM Integration Card */}
        <motion.div className="bg-gray-800 p-8 rounded-lg shadow-lg" variants={fadeInUp} whileHover={{ scale: 1.02 }}>
          <h3 className="text-2xl font-bold text-green-400 mb-4">CRM Integration: Improve Customer Relationships with Seamless Data Tracking</h3>
          <p className="text-lg leading-relaxed mb-4">
            Customer relationship management is at the heart of every successful business. Our CRM integration services connect your CRM system to other parts of your business, automating data entry, lead tracking, and customer follow-ups to ensure no opportunity is missed.
          </p>
          <p className="font-semibold text-green-400 mb-2">Why It Matters:</p>
          <ul className="list-disc list-inside text-lg mb-6">
            <li>Better Lead Management: Businesses using automated CRM systems report up to 29% higher sales.</li>
            <li>Enhanced Customer Experience: Timely follow-ups ensure customers feel valued, boosting satisfaction and loyalty.</li>
            <li>Data-Driven Insights: Automated CRMs provide real-time insights into your sales pipeline and customer journey.</li>
          </ul>
          <p className="text-lg leading-relaxed italic mb-12">
            <span className="text-green-400">Example:</span> According to Nucleus Research, businesses that invest in CRM integration see an average ROI of $8.71 for every dollar spent.
          </p>
        </motion.div>

        {/* Social Media Automation Card */}
        <motion.div className="bg-gray-800 p-8 rounded-lg shadow-lg" variants={fadeInUp} whileHover={{ scale: 1.02 }}>
          <h3 className="text-2xl font-bold text-green-400 mb-4">Social Media Automation: Amplify Your Brand, Effortlessly</h3>
          <p className="text-lg leading-relaxed mb-4">
            Managing social media can feel like a full-time job. Our social media automation services allow you to schedule posts, engage with followers, and track performance across platforms, all without constant oversight.
          </p>
          <p className="font-semibold text-green-400 mb-2">Why It Matters:</p>
          <ul className="list-disc list-inside text-lg mb-6">
            <li>Time Savings: Businesses that automate social media management can save an average of six hours per week.</li>
            <li>Consistent Presence: Automated posts ensure your brand stays visible even during off-hours or busy periods.</li>
            <li>Better Engagement: Consistent social media activity can lead to a 33% increase in follower engagement.</li>
          </ul>
          <p className="text-lg leading-relaxed italic mb-12">
            <span className="text-green-400">Example:</span> A study by Buffer found that businesses that automate their social media posts see a 25% increase in overall engagement and a 35% increase in lead generation.
          </p>
        </motion.div>

        {/* Expense Management Automation Card */}
        <motion.div className="bg-gray-800 p-8 rounded-lg shadow-lg" variants={fadeInUp} whileHover={{ scale: 1.02 }}>
          <h3 className="text-2xl font-bold text-green-400 mb-4">Expense Management Automation: Gain Full Control Over Your Finances</h3>
          <p className="text-lg leading-relaxed mb-4">
            Managing expenses can be a time-consuming and error-prone process. Our expense management automation services simplify financial tracking by automating categorization, reporting, and budgeting.
          </p>
          <p className="font-semibold text-green-400 mb-2">Why It Matters:</p>
          <ul className="list-disc list-inside text-lg mb-6">
            <li>Better Budget Control: Automated tracking provides real-time insights into spending, allowing you to adjust budgets efficiently.</li>
            <li>Simplified Reporting: Automation tools handle categorization and reporting, removing manual effort.</li>
            <li>Tax Season Ready: Automated tracking simplifies tax preparation, making it smoother and less stressful.</li>
          </ul>
          <p className="text-lg leading-relaxed italic mb-12">
            <span className="text-green-400">Example:</span> According to Aberdeen Group, companies using automated expense management systems see a 58% reduction in processing time and a 40% reduction in costs.
          </p>
        </motion.div>

        {/* Web Development & Custom Solutions Card */}
        <motion.div className="bg-gray-800 p-8 rounded-lg shadow-lg" variants={fadeInUp} whileHover={{ scale: 1.02 }}>
          <h3 className="text-2xl font-bold text-green-400 mb-4">Web Development & Custom Solutions: Build Digital Experiences That Convert</h3>
          <p className="text-lg leading-relaxed mb-4">
            We offer a full suite of web development services tailored to your specific needs. Whether it's a high-converting website, custom app, or an integration, <span className="text-green-500">gigaWorkflow</span> has you covered.
          </p>
          <p className="font-semibold text-green-400 mb-2">What We Offer:</p>
          <ul className="list-disc list-inside text-lg mb-6">
            <li>Responsive Web Development: Beautiful, responsive websites optimized for conversions and user engagement.</li>
            <li>Custom Applications: Tailored solutions to enhance your workflow and provide value to users.</li>
            <li>Bespoke Integrations: Seamless integrations between your existing tools and custom solutions.</li>
          </ul>
          <p className="text-lg leading-relaxed italic mb-12">
            <span className="text-green-400">Example:</span> According to Google, businesses with optimized, mobile-friendly websites experience a 50% increase in mobile traffic and a 32% boost in sales.
          </p>
        </motion.div>
      </div>

      {/* Final Call to Action */}
      <motion.div className="mt-16 text-center" variants={fadeInUp}>
        <h2 className="text-3xl font-bold text-green-500 mb-8">Take the First Step Toward a Smarter Business</h2>
        <p className="text-lg leading-relaxed mb-6">
          At <span className="text-green-500">gigaWorkflow</span>, we believe that businesses of any size can benefit from automation and streamlined digital solutions. Let us help you remove the friction from your daily operations so that you can focus on driving growth and success.
        </p>
        <motion.a
          href="/contact"
          className="bg-green-500 text-black px-8 py-4 rounded-full font-bold text-lg hover:bg-green-600 transition-colors"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          Contact Us
        </motion.a>
      </motion.div>
    </motion.div>
  );
};

export default ServicesPage;

import React from 'react';
import { motion } from 'framer-motion';

const MernCryptoDashboard = () => {
  return (
    <div className="min-h-screen bg-black text-white py-16 px-4">
      {/* Top Image */}
      <div className="container mx-auto mb-8">
        <img
          src="/assets/mern.png"
          alt="MERN Crypto Dashboard Example 1"
          className="w-80 h-80 object-cover rounded-lg shadow-lg mx-auto"
        />
      </div>

      {/* Main Text Content */}
      <div className="container mx-auto text-center">
        <motion.h1
          className="text-5xl font-bold text-green-500 mb-8"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          MERN Crypto Dashboard - A Showcase of Dynamic Data Integration and Scalable Login Systems
        </motion.h1>
        <motion.p
          className="text-xl text-gray-400 mb-8"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.6, delay: 0.3 }}
        >
          Data drives decisions. Having real-time, easily accessible information is vital for businesses across industries. The gigaWorkflow MERN Crypto Dashboard is an example of how a powerful combination of MongoDB, Express, React, and Node.js (MERN stack) can create seamless user experiences while showcasing essential features such as login systems, dynamic data feeds, and secure account management. Though this dashboard highlights crypto market data, its underlying architecture can be adapted for countless other applications.
        </motion.p>

        <h2 className="text-3xl font-semibold text-green-400 mb-6">
  The Power of Login Features for Business Applications
</h2>
<p className="text-lg text-gray-400 mb-6">
  A well-designed login feature is more than just a gateway to access—it’s the foundation of user management. The login system showcased in this crypto dashboard is scalable, secure, and highly adaptable, making it suitable for a variety of business needs:
</p>

<ul className="list-disc list-inside text-left mx-auto max-w-3xl mb-8 text-gray-400">
  <li>
    <strong>Subscription Management Systems:</strong> From SaaS platforms to membership sites, this login system can be integrated into subscription managers, ensuring that users have secure, tiered access to premium content and services.
  </li>
  <li>
    <strong>Client Portals:</strong> Businesses can use this architecture to build custom client portals where users can log in, track orders, manage projects, or view personalized data.
  </li>
  <li>
    <strong>E-commerce Dashboards:</strong> Extend the login feature to manage user accounts, order histories, and wish lists in online stores or marketplaces.
  </li>
  <li>
    <strong>Content Management Systems (CMS):</strong> Provide secure access to a back-end CMS where users can log in to manage, create, or edit content.
  </li>
</ul>


        {/* Midway Image */}
        <div className="container mx-auto mb-8">
          <img
            src="/assets/Cryptodb1.png"
            alt="MERN Crypto Dashboard Example 2"
            className="w-80 h-80 object-cover rounded-lg shadow-lg mx-auto"
          />
        </div>

        <h2 className="text-3xl font-semibold text-green-400 mb-6">
  API and RSS Feed Integration for Real-Time Data
</h2>
<p className="text-lg text-gray-400 mb-6">
  The crypto dashboard isn’t just about user management—it’s about delivering real-time insights. By integrating API and RSS feeds, the dashboard pulls live data directly from crypto markets, offering up-to-date information at a glance. This same approach can be applied across industries:
</p>

<ul className="list-disc list-inside text-left mx-auto max-w-3xl mb-8 text-gray-400">
  <li>
    <strong>Financial Data:</strong> Use the system to pull stock market or financial reports, delivering real-time analytics to users.
  </li>
  <li>
    <strong>News Aggregation:</strong> Leverage RSS feeds to compile news updates from various sources, creating a centralized hub for the latest in any industry.
  </li>
  <li>
    <strong>Sales and Marketing Insights:</strong> Connect APIs from CRMs or marketing platforms to present live sales data, lead generation stats, or campaign performance in one cohesive dashboard.
  </li>
</ul>


        <h2 className="text-3xl font-semibold text-green-400 mb-6">
          Flexibility and Adaptability
        </h2>
        <p className="text-lg text-gray-400 mb-6">
          The versatility of this MERN crypto dashboard lies in its modular design. Any aspect of the system—from the login functionality to the data feeds—can be adapted to suit your specific business needs. Whether you’re managing sensitive user data, integrating real-time analytics, or creating subscription-based services, this system provides a flexible, scalable foundation that can grow with your business.
        </p>

        <h2 className="text-3xl font-semibold text-green-400 mb-6">
          The gigaWorkflow Advantage
        </h2>
        <p className="text-lg text-gray-400 mb-12">
          At gigaWorkflow, we believe in building systems that not only work but evolve. This MERN crypto dashboard demonstrates how our approach to development ensures long-term scalability, security, and efficiency. Whether you’re looking for a simple login system or a fully integrated data platform, our solutions are designed to meet the demands of your business—today and in the future.
        </p>

        {/* Buttons */}
        <div className="space-y-4 md:space-y-0 md:space-x-4 flex flex-col md:flex-row justify-center items-center">
          <a 
            href="https://www.bigbraincrypto.net" 
            className="w-full md:w-auto px-6 py-3 bg-green-600 text-white font-semibold rounded-lg shadow-md hover:bg-green-500 transition duration-300 text-center"
          >
            Visit our CryptoDB
          </a>
          <a 
            href="/contact" 
            className="w-full md:w-auto px-6 py-3 bg-transparent border-2 border-green-600 text-green-600 font-semibold rounded-lg shadow-md hover:bg-green-600 hover:text-white transition duration-300 text-center"
          >
            Contact Us
          </a>
        </div>
      </div>
    </div>
  );
};

export default MernCryptoDashboard;

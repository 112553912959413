import React from 'react';
import { motion } from 'framer-motion';

const GigaGallery = () => {
  return (
    <div className="min-h-screen bg-black text-white py-16 px-4">
      {/* Top Image */}
      <div className="container mx-auto mb-8">
        <img
          src="/assets/steampunk9.webp"
          alt="GigaGallery Example 1"
          className="w-80 h-80 object-cover rounded-lg shadow-lg mx-auto"
        />
      </div>

      {/* Main Text Content */}
      <div className="container mx-auto text-center">
        <motion.h1
          className="text-5xl font-bold text-green-500 mb-8"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          gigaGallery - Elevate Your Business Through Stunning Web Design
        </motion.h1>
        <motion.p
          className="text-xl text-gray-400 mb-8"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.6, delay: 0.3 }}
        >
          A well-built website is often your business’s most critical tool, functioning as both a virtual storefront and a key driver of growth. With gigaGallery, we’ve created a showcase of web-building expertise using React, one of the most powerful front-end libraries available today.
        </motion.p>

        <h2 className="text-3xl font-semibold text-green-400 mb-6">
          Why Does Your Business Need a Website?
        </h2>
        <p className="text-lg text-gray-400 mb-6">
          Research shows that nearly 90% of consumers won’t return to a website after a poor user experience. This means that your site’s design and performance directly impact customer retention and satisfaction. Furthermore, businesses with a strong web presence can see up to 15% higher revenue growth compared to competitors with outdated or no websites. More than half of consumers—57%—say they won’t recommend a business with a poorly designed mobile site.
        </p>

        {/* Midway Image */}
        <div className="container mx-auto mb-8">
          <img
            src="/assets/asia23.webp"
            alt="GigaGallery Example 1"
            className="w-80 h-80 object-cover rounded-lg shadow-lg mx-auto"
          />
        </div>

        <h2 className="text-3xl font-semibold text-green-400 mb-6">
          What gigaGallery Style Can Do for Your Business
        </h2>
        <p className="text-lg text-gray-400 mb-6">
          gigaGallery demonstrates how modern web technologies like React can transform your business’s online identity. React enables fast loading times, dynamic user experiences, and seamless navigation—all of which are critical for keeping potential customers engaged.
        </p>

        <ul className="list-disc list-inside text-left mx-auto max-w-3xl mb-8 text-gray-400">
          <li>Boost engagement: Attractive, intuitive sites keep visitors on your platform longer, increasing the chances of conversion.</li>
          <li>Improve SEO: Well-built websites perform better in search engines, bringing more organic traffic to your business.</li>
          <li>Grow with you: React-based sites are scalable and adaptable, making future updates easy and cost-effective.</li>
        </ul>

        <h2 className="text-3xl font-semibold text-green-400 mb-6">
          The gigaWorkflow Advantage
        </h2>
        <p className="text-lg text-gray-400 mb-12">
          We approach web development as a strategic asset, not just a technical requirement. The choices we make in design and functionality are guided by what brings real value to your business. With nearly half of users expecting websites to load in 2 seconds or less, we prioritize speed, reliability, and aesthetics to create a high-performance experience.
        </p>

        {/* Buttons */}
        <div className="space-y-4 md:space-y-0 md:space-x-4 flex flex-col md:flex-row justify-center items-center">
          <a 
            href="https://www.gigagallery.xyz" 
            className="w-full md:w-auto px-6 py-3 bg-green-600 text-white font-semibold rounded-lg shadow-md hover:bg-green-500 transition duration-300 text-center"
          >
            Visit GigaGallery
          </a>
          <a 
            href="/contact" 
            className="w-full md:w-auto px-6 py-3 bg-transparent border-2 border-green-600 text-green-600 font-semibold rounded-lg shadow-md hover:bg-green-600 hover:text-white transition duration-300 text-center"
          >
            Contact Us
          </a>
        </div>
      </div>
    </div>
  );
};

export default GigaGallery;

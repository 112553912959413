import React from 'react';
import { Link } from 'react-router-dom';

const PortfolioPage = () => {
  return (
    <div className="min-h-screen bg-black py-16">
      <h1 className="text-4xl font-bold text-center text-green-500 mb-8">Our Portfolio</h1>
      <p className="text-center mb-8 text-gray-400">
        Explore our portfolio of work across various industries, showcasing our expertise in web development, automation, and more.
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {/* Portfolio Project 1 */}
        <div className="bg-gray-900 p-6 rounded-lg shadow-md">
          <Link to="/projects/project1">
            <img src="./assets/doodle5.webp" alt="Project 1" className="mb-4 rounded-lg" />
            <h3 className="text-xl font-semibold text-white mb-2">gigaGallery - React Website</h3>
          </Link>
          <p className="text-gray-400">
           Your website is the face of your business; gigaWorkflow can make you a site your clients will love. 
          </p>
        </div>

        {/* Portfolio Project 2 */}
        <div className="bg-gray-900 p-6 rounded-lg shadow-md">
          <Link to="/projects/project2">
            <img src="./assets/image7.webp" alt="Project 2" className="mb-4 rounded-lg" />
            <h3 className="text-xl font-semibold text-white mb-2">gigaWorkflow Automated Blog</h3>
          </Link>
          <p className="text-gray-400">
            Automating your blog can educate your clients about your services without extra effort or cost on your part.
          </p>
        </div>

        {/* Portfolio Project 3 */}
        <div className="bg-gray-900 p-6 rounded-lg shadow-md">
          <Link to="/projects/project3">
            <img src="./assets/PDF.png" alt="Project 3" className="mb-4 rounded-lg" />
            <h3 className="text-xl font-semibold text-white mb-2">PDF Parser for Invoicing</h3>
          </Link>
          <p className="text-gray-400">
            This versatile workflow can be customised to create all sorts of productivity improvements for your business.
          </p>
        </div>
        <div className="bg-gray-900 p-6 rounded-lg shadow-md">
          <Link to="/projects/project4">
            <img src="./assets/indeed.png" alt="Project 4" className="mb-4 rounded-lg" />
            <h3 className="text-xl font-semibold text-white mb-2">Indeed Job Scraper</h3>
          </Link>
          <p className="text-gray-400">
           This workflow shows the power of web scraping combined with ChatGPT responses; a combination that can make your business more efficient.
          </p>
        </div>

        {/* Portfolio Project 2 */}
        <div className="bg-gray-900 p-6 rounded-lg shadow-md">
          <Link to="/projects/project5">
            <img src="./assets/Cryptodb.png" alt="Project 5" className="mb-4 rounded-lg" />
            <h3 className="text-xl font-semibold text-white mb-2">MERN Crypto Dashboard</h3>
          </Link>
          <p className="text-gray-400">
            MERN powered login features can be utilised to implement any number of custom solutions for your business.
          </p>
        </div>

        {/* Portfolio Project 3 */}
        <div className="bg-gray-900 p-6 rounded-lg shadow-md">
          <Link to="/projects/project6">
            <img src="./assets/CRM.png" alt="Project 6" className="mb-4 rounded-lg" />
            <h3 className="text-xl font-semibold text-white mb-2">Automated Customer Relationship Management System</h3>
          </Link>
          <p className="text-gray-400">
            CRMs can be used to improve client relations and optimise how your business handles customer relations. 
          </p>
        </div>
      </div>
    </div>
  );
};

export default PortfolioPage;

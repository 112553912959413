import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false); // State to toggle menu

  const toggleMenu = () => {
    setIsOpen(!isOpen); // Toggle the mobile menu open/close
  };

  return (
    <header className="bg-black shadow-md">
      <div className="container mx-auto flex justify-between items-center py-4 px-4 sm:px-0">
        {/* Logo */}
        <div>
          <Link to="/" className="text-2xl font-bold text-green-500">
            gigaWorkflow.xyz
          </Link>
        </div>

        {/* Hamburger icon for mobile */}
        <div className="sm:hidden">
          <button
            onClick={toggleMenu}
            className="text-green-500 focus:outline-none"
          >
            {!isOpen ? (
              /* Hamburger Icon when closed */
              <svg
                className="w-8 h-8"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              </svg>
            ) : (
              /* Close Icon (X) when open */
              <svg
                className="w-8 h-8"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            )}
          </button>
        </div>

        {/* Navigation Links (Hidden on mobile, always visible on desktop) */}
        <nav className="hidden sm:block">
          <ul className="flex space-x-6">
            <li>
              <Link to="/services" className="text-white hover:text-green-500">Services</Link>
            </li>
            <li>
              <Link to="/portfolio" className="text-white hover:text-green-500">Portfolio</Link>
            </li>
            <li>
              <Link to="/blog" className="text-white hover:text-green-500">Blog</Link>
            </li>
            <li>
              <Link to="/contact" className="text-white hover:text-green-500">Contact</Link>
            </li>
          </ul>
        </nav>
      </div>

      {/* Full-screen black overlay and centered menu */}
      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-90 flex justify-center items-center z-50">
          <div className="absolute top-4 right-4">
            <button
              onClick={toggleMenu}
              className="text-green-500 focus:outline-none"
            >
              <svg
                className="w-8 h-8"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>

          {/* Menu in center */}
          <nav>
            <ul className="flex flex-col items-center space-y-8 text-3xl">
              <li>
                <Link to="/services" className="text-white hover:text-green-500" onClick={toggleMenu}>Services</Link>
              </li>
              <li>
                <Link to="/portfolio" className="text-white hover:text-green-500" onClick={toggleMenu}>Portfolio</Link>
              </li>
              <li>
                <Link to="/blog" className="text-white hover:text-green-500" onClick={toggleMenu}>Blog</Link>
              </li>
              <li>
                <Link to="/contact" className="text-white hover:text-green-500" onClick={toggleMenu}>Contact</Link>
              </li>
            </ul>
          </nav>
        </div>
      )}
    </header>
  );
};

export default Header;

import React from 'react';
import { motion } from 'framer-motion';

const CRMSystem = () => {
  return (
    <div className="min-h-screen bg-black text-white py-16 px-4">
      {/* Top Image */}
      <div className="container mx-auto mb-8">
        <img
          src="/assets/crmemail.png"
          alt="CRM System Example 1"
          className="w-80 h-80 object-cover rounded-lg shadow-lg mx-auto"
        />
      </div>

      {/* Main Text Content */}
      <div className="container mx-auto text-center">
        <motion.h1
          className="text-5xl font-bold text-green-500 mb-8"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          CRM System - Powering Business Growth Through Intelligent Automation
        </motion.h1>
        <motion.p
          className="text-xl text-gray-400 mb-8"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.6, delay: 0.3 }}
        >
          A Customer Relationship Management (CRM) system goes far beyond storing contacts—it becomes the heart of your business operations. The gigaWorkflow CRM system offers more than just organization; it brings automation into the equation, streamlining processes, improving customer retention, and boosting revenue growth.
        </motion.p>

        <h2 className="text-3xl font-semibold text-green-400 mb-6">
          Automating Key Business Processes
        </h2>
        <p className="text-lg text-gray-400 mb-6">
          The gigaWorkflow CRM is designed to automate a range of tasks, allowing you to stay ahead without manual intervention. Emails can be sent automatically after client interactions or purchases, ensuring timely and personalized communication. Calendar updates are handled seamlessly, syncing appointments and sending reminders without you having to lift a finger. Tasks can be assigned automatically to team members, ensuring workflows stay on track and priorities are managed efficiently.
        </p>

        <p className="text-lg text-gray-400 mb-6">
          Imagine the power of having follow-up emails generated immediately after a client engagement, or appointments scheduled automatically after an inquiry. Our CRM system manages it all, freeing up your time and minimizing human error.
        </p>

        {/* Midway Image */}
        <div className="container mx-auto mb-8">
          <img
            src="/assets/cal.png"
            alt="CRM System Example 2"
            className="w-80 h-80 object-cover rounded-lg shadow-lg mx-auto"
          />
        </div>

        <h2 className="text-3xl font-semibold text-green-400 mb-6">
          Boosting Customer Retention and Driving Growth
        </h2>
        <p className="text-lg text-gray-400 mb-6">
          Customer retention is crucial for business growth. Research shows that increasing retention rates by just 5% can lead to a 25-95% boost in profits. The gigaWorkflow CRM is built to support that growth. By tracking detailed customer data, our CRM enables personalized interactions at the right moments, such as sending a tailored offer after a client completes a purchase or sending out follow-ups based on past interactions.
        </p>

        <p className="text-lg text-gray-400 mb-6">
          With the data-driven insights provided by the CRM, your team can make informed decisions about marketing, sales, and customer service strategies, ensuring you stay proactive in meeting customer needs. This isn’t just about keeping up with clients—it’s about exceeding their expectations and keeping them engaged.
        </p>

        <h2 className="text-3xl font-semibold text-green-400 mb-6">
          Unlocking Business Potential with Custom Automation
        </h2>
        <p className="text-lg text-gray-400 mb-6">
          Beyond managing customers, the gigaWorkflow CRM can adapt to fit the unique needs of any business. It can generate detailed sales reports, automate marketing campaigns based on user behavior, and even manage client onboarding. Whether you need to monitor inventory, create personalized marketing campaigns, or handle service tickets, the flexibility of the system allows it to grow with your business and respond to your specific needs.
        </p>

        <p className="text-lg text-gray-400 mb-6">
          For example, our CRM can automatically generate weekly sales reports, giving you real-time insights into team performance and pipeline health. It can sync with marketing platforms to send out targeted campaigns at the right moment, increasing engagement and driving conversions. It can even handle service ticket assignments, ensuring customer issues are addressed promptly and efficiently.
        </p>

        <h2 className="text-3xl font-semibold text-green-400 mb-6">
          The gigaWorkflow Advantage
        </h2>
        <p className="text-lg text-gray-400 mb-12">
          At gigaWorkflow, we recognize that automation is not just about streamlining tasks—it’s about driving meaningful results for your business. Our CRM system is built to offer a flexible, scalable solution that empowers your team to deliver high-quality service while freeing up valuable time. Whether you're focused on improving customer retention, automating routine processes, or scaling operations, the gigaWorkflow CRM provides the functionality and adaptability to help you grow.
        </p>

        {/* Buttons */}
        <div className="space-y-4 md:space-y-0 md:space-x-4 flex flex-col md:flex-row justify-center items-center">
          <a 
            href="/contact" 
            className="w-full md:w-auto px-6 py-3 bg-transparent border-2 border-green-600 text-green-600 font-semibold rounded-lg shadow-md hover:bg-green-600 hover:text-white transition duration-300 text-center"
          >
            Contact Us
          </a>
        </div>
      </div>
    </div>
  );
};

export default CRMSystem;
